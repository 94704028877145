import React from 'react'
var throttle = require('lodash.throttle');
const StackBlur = require('stackblur-canvas');

const max = 800;

function roundRect(ctx, x, y, width, height, radius, fill, stroke) {
  if (typeof stroke === 'undefined') {
    stroke = true;
  }
  if (typeof radius === 'undefined') {
    radius = 0;
  }
  if (typeof radius === 'number') {
    radius = {tl: radius, tr: radius, br: radius, bl: radius};
  } else {
    var defaultRadius = {tl: 0, tr: 0, br: 0, bl: 0};
    for (var side in defaultRadius) {
      radius[side] = radius[side] || defaultRadius[side];
    }
  }
  ctx.beginPath();
  ctx.moveTo(x + radius.tl, y);
  ctx.lineTo(x + width - radius.tr, y);
  ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
  ctx.lineTo(x + width, y + height - radius.br);
  ctx.quadraticCurveTo(x + width, y + height, x + width - radius.br, y + height);
  ctx.lineTo(x + radius.bl, y + height);
  ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
  ctx.lineTo(x, y + radius.tl);
  ctx.quadraticCurveTo(x, y, x + radius.tl, y);
  ctx.closePath();
  if (fill) {
    ctx.fill();
  }
  if (stroke) {
    ctx.stroke();
  }
}

function canvas2image(canvas) {
  return new Promise((resolve, reject) => {
    let image = new Image();
    image.src = canvas.toDataURL('image/png');
    image.onload = () => {
      resolve(image);
    }
  })
}

class MyCanvas extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.ref = React.createRef();
    this.precomposed = [];
    this.throttled = throttle(this.buildCanvas, 33);
  }

  async buildCanvasEasy() {
    let img = this.props.img;
    let canvas = this.ref.current;
    this.props.pullback(canvas);
    let width = img.width;
    let height = img.height;
    if (width > max || height > max) {
      if (width > height) {
        height = Math.round((height / width) * max);
        width = max;
      } else {
        width = Math.round((width / height) * max);
        height = max;
      }
    }
    canvas.width = width;
    canvas.height = height;
    let ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(img,0,0,width,height);

    ctx.fillStyle = "#000000";
    ctx.globalAlpha = 1.0;
    ctx.filter = "none";
    ctx.imageSmoothingEnabled = true;

    let blurs = this.props.blurs;

    for (let blur of blurs) {
      if (blur.type === 'text') {
        ctx.fillStyle = blur.color;
        ctx.globalAlpha = blur.level;
        ctx.fillRect(blur.tlx, blur.tly, (blur.brx - blur.tlx), (blur.bry - blur.tly));
        let cw = Math.round(blur.brx - blur.tlx);
        cw = Math.max(cw, cw - 10);
        let fontsize = Math.min(30, 1.5 * cw / blur.text.length);
        ctx.font = fontsize + "px Courier";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.globalAlpha = 1.0;
        ctx.fillStyle = "#FFFFFF";
        ctx.fillText(blur.text, Math.round((blur.tlx + blur.brx) / 2), Math.round((blur.tly + blur.bry) / 2), cw);
        ctx.fillStyle = "#000000";
      } else if (blur.type === 'blur') {
        if ((blur.brx - blur.tlx) > 0 && (blur.bry - blur.tly) > 0) {
          StackBlur.canvasRGB(canvas, blur.tlx, blur.tly, (blur.brx - blur.tlx), (blur.bry - blur.tly), Math.round((canvas.width/8) * blur.level));
        }
      } else if (blur.type === 'pixel') {
        let initial = ctx.getImageData(0, 0, canvas.width, canvas.height);
        let w = canvas.width / (50 * (blur.level + 0.02));
        let h = canvas.height / (50 * (blur.level + 0.02));
        let startImage = await canvas2image(canvas);
        let tempcanvas = document.createElement('canvas');
        tempcanvas.width = w;
        tempcanvas.height = h;
        let tempctx = tempcanvas.getContext('2d');
        tempctx.drawImage(startImage, 0, 0, canvas.width, canvas.height, 0, 0, w, h);
        let endImage = await canvas2image(tempcanvas);
        ctx.imageSmoothingEnabled = false;
        ctx.drawImage(endImage, 0, 0, canvas.width, canvas.height);
        ctx.imageSmoothingEnabled = true;
        let final = ctx.getImageData(blur.tlx, blur.tly, (blur.brx - blur.tlx), (blur.bry - blur.tly));
        ctx.putImageData(initial, 0, 0);
        ctx.putImageData(final, blur.tlx, blur.tly, 0, 0, (blur.brx - blur.tlx), (blur.bry - blur.tly));
      } else {
        ctx.fillStyle = blur.color;
        ctx.globalAlpha = blur.level;
        ctx.fillRect(blur.tlx, blur.tly, (blur.brx - blur.tlx), (blur.bry - blur.tly));
        ctx.globalAlpha = 1.0;
        ctx.fillStyle = "#000000";
      }
    }
  }

  async buildCanvas() {
    if (!('createImageBitmap' in window)) return await this.buildCanvasEasy();

    let img = this.props.img;
    let canvas = this.ref.current;
    this.props.pullback(canvas);

    let width = img.width;
    let height = img.height;

    if (width > max || height > max) {
      if (width > height) {
        height = Math.round((height / width) * max);
        width = max;
      } else {
        width = Math.round((width / height) * max);
        height = max;
      }
    }

    canvas.width = width;
    canvas.height = height;
    let ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(img,0,0,width,height);

    ctx.fillStyle = "#000000";
    ctx.globalAlpha = 1.0;
    ctx.filter = "none";
    ctx.imageSmoothingEnabled = true;

    let prekey = [];

    let blurs = this.props.blurs;

    for (let blur of blurs) {
      let w = canvas.width, h = canvas.height;
      let clearData = ctx.getImageData(0, 0, w, h);
      let clearMap = await createImageBitmap(canvas);

      ctx.globalCompositeOperation = 'destination-out';
      let r = Math.min((blur.brx - blur.tlx), (blur.bry - blur.tly)) * blur.radius * 0.5;
      roundRect(ctx, blur.tlx, blur.tly, (blur.brx - blur.tlx), (blur.bry - blur.tly), r, "black", "black");
      ctx.globalCompositeOperation = 'source-over';
      let holeMap = await createImageBitmap(canvas);

      let thiskey = JSON.stringify(prekey.concat({type: blur.type, level: blur.level, text: blur.text}));
      let itry = this.precomposed[thiskey];
      if (itry && blur.type !== 'text') {
        ctx.putImageData(itry, 0, 0);
      } else {
        ctx.putImageData(clearData, 0, 0);
        if (blur.type === 'text') {
          ctx.fillStyle = blur.color;
          ctx.globalAlpha = blur.level;
          ctx.fillRect(0, 0, canvas.width, canvas.height);
          let cw = Math.round(blur.brx - blur.tlx);
          cw = Math.max(cw, cw - 10);
          let fontsize = Math.min(30, 1.5 * cw / blur.text.length);
          ctx.font = fontsize + "px Courier";
          ctx.textAlign = "center";
          ctx.textBaseline = "middle";
          ctx.globalAlpha = 1.0;
          ctx.fillStyle = "#FFFFFF";
          ctx.fillText(blur.text, Math.round((blur.tlx + blur.brx) / 2), Math.round((blur.tly + blur.bry) / 2), cw);
          ctx.fillStyle = "#000000";
        } else if (blur.type === 'box') {
          ctx.fillStyle = blur.color;
          ctx.globalAlpha = blur.level;
          ctx.fillRect(0, 0, canvas.width, canvas.height);
          ctx.globalAlpha = 1.0;
          ctx.fillStyle = "#000000";
        } else if (blur.type === 'blur') {
          ctx.drawImage(clearMap,0,0);
          StackBlur.canvasRGB(canvas, 0, 0, w, h, Math.round((w/8) * blur.level));
        } else if (blur.type === 'pixel') {
          let w = canvas.width / (50 * (blur.level + 0.02));
          let h = canvas.height / (50 * (blur.level + 0.02));
          ctx.drawImage(clearMap,0,0,w,h);
          let smallData = ctx.getImageData(0,0,w,h);
          let smallMap = await createImageBitmap(smallData);
          ctx.imageSmoothingEnabled = false;
          ctx.drawImage(smallMap,0,0,canvas.width,canvas.height);
          ctx.imageSmoothingEnabled = true;
        } else if (blur.type === 'waves') {
          ctx.filter = 'url(#' + blur.type + Math.round(blur.level * 100) + ')';
          ctx.drawImage(clearMap,0,0);
          ctx.filter = "none";
        } else {
          ctx.filter = 'url(#' + blur.type + ')';
          ctx.globalAlpha = blur.level;
          ctx.drawImage(clearMap,0,0);
          ctx.globalAlpha = 1.0;
          ctx.filter = "none";
        }
        this.precomposed[thiskey] = ctx.getImageData(0, 0, w, h);
      }
      ctx.drawImage(holeMap,0,0,w,h);
      prekey.push(blur);
    }
  }

  componentDidMount() {
    this.throttled();
  }

  componentDidUpdate() {
    this.throttled();
  }

  render() {
    return (
      <canvas ref={this.ref}></canvas>
    );
  }
}

export default MyCanvas;
