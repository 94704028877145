import React from 'react';

class Select extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.ref = React.createRef();
  }

  componentDidMount() {
    this.addRect = this.addRect.bind(this);
    this.init2 = this.init2.bind(this);
    this.clear = this.clear.bind(this);
    this.mainDraw = this.mainDraw.bind(this);
    this.invalidate = this.invalidate.bind(this);
    this.getMouse = this.getMouse.bind(this);

    this.boxes2 = [];
    this.selectionHandles = [];
    this.canvas = this.ref.current;
    this.ctx = this.canvas.getContext('2d');
    this.img = this.props.img;
    let width = this.img.width;
    let height = this.img.height;
    if (width > this.props.max || height > this.props.max) {
      if (width > height) {
        height = Math.round((height / width) * this.props.max);
        width = this.props.max;
      } else {
        width = Math.round((width / height) * this.props.max);
        height = this.props.max;
      }
    }
    this.WIDTH = width;
    this.HEIGHT = height;
    this.canvas.width = this.WIDTH;
    this.canvas.height = this.HEIGHT;
    this.isDrag = false;
    this.isResizeDrag = false;
    this.expectResize = -1;
    this.mx = undefined;
    this.my = undefined;
    this.canvasValid = false;
    this.mySel = null;
    this.mySelColor = '#CC0000';
    this.scale = this.canvas.width / this.canvas.getBoundingClientRect().width;
    this.mySelWidth = Math.ceil(5 * this.scale);
    this.mySelBoxColor = 'darkred';
    this.mySelBoxSize = Math.ceil(15 * this.scale);
    this.ghostcanvas = undefined;
    this.gctx = undefined;
    this.offsetx = undefined;
    this.offsety = undefined;
    this.stylePaddingLeft = undefined;
    this.stylePaddingTop = undefined;
    this.styleBorderLeft = undefined;
    this.styleBorderTop = undefined;
    this.Box2 = function () {
      this.x = 0;
      this.y = 0;
      this.w = 1;
      this.h = 1;
      this.fill = '#444444';
    }

    let parent = this;
    this.Box2.prototype = {
      update: function() {
        if (this.i !== undefined) {
          let newBlurs = parent.props.blurs;
          newBlurs[this.i].tlx = Math.round(this.x + this.w);
          newBlurs[this.i].tly = Math.round(this.y + this.h);
          newBlurs[this.i].brx = Math.round(this.x);
          newBlurs[this.i].bry = Math.round(this.y);
          parent.props.setList({blurs: newBlurs});
        }
      },
      draw: function(context, optionalColor) {
          if (context === parent.gctx) {
            context.fillStyle = 'black';
          } else {
            context.fillStyle = this.fill;
          }
          context.fillRect(this.x,this.y,this.w,this.h);
        if (parent.mySel === this) {
          context.strokeStyle = parent.mySelColor;
          context.lineWidth = parent.mySelWidth;
          context.strokeRect(this.x,this.y,this.w,this.h);
          var half = parent.mySelBoxSize / 2;
          parent.selectionHandles[0].x = this.x-half;
          parent.selectionHandles[0].y = this.y-half;
          parent.selectionHandles[1].x = this.x+this.w/2-half;
          parent.selectionHandles[1].y = this.y-half;
          parent.selectionHandles[2].x = this.x+this.w-half;
          parent.selectionHandles[2].y = this.y-half;
          parent.selectionHandles[3].x = this.x-half;
          parent.selectionHandles[3].y = this.y+this.h/2-half;
          parent.selectionHandles[4].x = this.x+this.w-half;
          parent.selectionHandles[4].y = this.y+this.h/2-half;
          parent.selectionHandles[6].x = this.x+this.w/2-half;
          parent.selectionHandles[6].y = this.y+this.h-half;
          parent.selectionHandles[5].x = this.x-half;
          parent.selectionHandles[5].y = this.y+this.h-half;
          parent.selectionHandles[7].x = this.x+this.w-half;
          parent.selectionHandles[7].y = this.y+this.h-half;
          context.fillStyle = parent.mySelBoxColor;
          for (var i = 0; i < 8; i ++) {
            var cur = parent.selectionHandles[i];
            context.fillRect(cur.x, cur.y, parent.mySelBoxSize, parent.mySelBoxSize);
          }
        }
      }
    }
    this.init2();
    this.componentDidUpdate();
  }

  componentDidUpdate(prevProps) {
    this.canvas = this.ref.current;
    this.ctx = this.canvas.getContext('2d');
    this.canvas.width = this.WIDTH;
    this.canvas.height = this.HEIGHT;

    if (this.boxes2.length !== this.props.blurs.length) {
      this.boxes2 = [];
      for (let [i,obj] of this.props.blurs.entries()) {
        this.addRect(obj.brx, obj.bry, (obj.tlx - obj.brx), (obj.tly - obj.bry), 'rgba(0,0,0,0)', i, obj.id);
      }
    }

    this.mainDraw();
  }

  addRect(x, y, w, h, fill, i, id) {
    var rect = new this.Box2();
    rect.x = x;
    rect.y = y;
    rect.w = w
    rect.h = h;
    rect.i = i;
    rect.id = id;
    rect.fill = fill;
    this.boxes2.push(rect);
    this.invalidate();
    return rect;
  }

  init2() {
    this.ghostcanvas = document.createElement('canvas');
    this.ghostcanvas.height = this.HEIGHT;
    this.ghostcanvas.width = this.WIDTH;
    this.gctx = this.ghostcanvas.getContext('2d');

    this.canvas.onselectstart = function () { return false; }

    if (document.defaultView && document.defaultView.getComputedStyle) {
      this.stylePaddingLeft = parseInt(document.defaultView.getComputedStyle(this.canvas, null)['paddingLeft'], 10)     || 0;
      this.stylePaddingTop  = parseInt(document.defaultView.getComputedStyle(this.canvas, null)['paddingTop'], 10)      || 0;
      this.styleBorderLeft  = parseInt(document.defaultView.getComputedStyle(this.canvas, null)['borderLeftWidth'], 10) || 0;
      this.styleBorderTop   = parseInt(document.defaultView.getComputedStyle(this.canvas, null)['borderTopWidth'], 10)  || 0;
    }

    for (var i = 0; i < 8; i ++) {
      var rect = new this.Box2();
      this.selectionHandles.push(rect);
    }
  }

  clear(c) {
    c.clearRect(0, 0, this.WIDTH, this.HEIGHT);
  }

  mainDraw() {
    if (this.boxes2.length === 1) this.mySel = this.boxes2[0];
    if (this.canvasValid === false) {
      this.clear(this.ctx);
      var l = this.boxes2.length;
      for (var i = 0; i < l; i++) {
        this.boxes2[i].draw(this.ctx);
      }
      this.canvasValid = true;
    }
  }

  myMove(e){
    if (this.isDrag && !this.isResizeDrag) {
      this.getMouse(e);
      this.mySel.x = this.mx - this.offsetx;
      this.mySel.y = this.my - this.offsety;
      this.mySel.update();
      this.invalidate();
    } else if (this.isResizeDrag) {
      var oldx = this.mySel.x;
      var oldy = this.mySel.y;

      switch (this.expectResize) {
        case 0:
          this.mySel.x = this.mx;
          this.mySel.y = this.my;
          this.mySel.w += oldx - this.mx;
          this.mySel.h += oldy - this.my;
          break;
        case 1:
          this.mySel.y = this.my;
          this.mySel.h += oldy - this.my;
          break;
        case 2:
          this.mySel.y = this.my;
          this.mySel.w = this.mx - oldx;
          this.mySel.h += oldy - this.my;
          break;
        case 3:
          this.mySel.x = this.mx;
          this.mySel.w += oldx - this.mx;
          break;
        case 4:
          this.mySel.w = this.mx - oldx;
          break;
        case 5:
          this.mySel.x = this.mx;
          this.mySel.w += oldx - this.mx;
          this.mySel.h = this.my - oldy;
          break;
        case 6:
          this.mySel.h = this.my - oldy;
          break;
        case 7:
          this.mySel.w = this.mx - oldx;
          this.mySel.h = this.my - oldy;
          break;
        default:
          break;
      }

      this.mySel.update();
      this.invalidate();
    }

    this.getMouse(e);
    if (this.mySel !== null && !this.isResizeDrag) {
      for (var i = 0; i < 8; i++) {

        var cur = this.selectionHandles[i];

        if (this.mx >= cur.x && this.mx <= cur.x + this.mySelBoxSize &&
            this.my >= cur.y && this.my <= cur.y + this.mySelBoxSize) {
          this.expectResize = i;
          this.invalidate();
          if (e.touches) this.isResizeDrag = true;

          switch (i) {
            case 0:
              this.canvas.style.cursor='nw-resize';
              break;
            case 1:
              this.canvas.style.cursor='n-resize';
              break;
            case 2:
              this.canvas.style.cursor='ne-resize';
              break;
            case 3:
              this.canvas.style.cursor='w-resize';
              break;
            case 4:
              this.canvas.style.cursor='e-resize';
              break;
            case 5:
              this.canvas.style.cursor='sw-resize';
              break;
            case 6:
              this.canvas.style.cursor='s-resize';
              break;
            case 7:
              this.canvas.style.cursor='se-resize';
              break;
            default:
              break;
          }
          return;
        }

      }
      this.isResizeDrag = false;
      this.expectResize = -1;
      this.canvas.style.cursor='auto';
    }

  }

  myDown(e){
    this.getMouse(e);

    if (this.expectResize !== -1) {
      this.isResizeDrag = true;
      return;
    }

    this.clear(this.gctx);

    var l = this.boxes2.length;
    for (var i = l-1; i >= 0; i--) {
      this.boxes2[i].draw(this.gctx, 'black');
      var imageData = this.gctx.getImageData(this.mx, this.my, 1, 1);

      if (imageData.data[3] > 0) {
        this.mySel = this.boxes2[i];
        this.offsetx = this.mx - this.mySel.x;
        this.offsety = this.my - this.mySel.y;
        this.mySel.x = this.mx - this.offsetx;
        this.mySel.y = this.my - this.offsety;
        this.isDrag = true;

        this.invalidate();
        this.clear(this.gctx);
        return;
      }
    }
    this.mySel = null;
    this.clear(this.gctx);
    this.invalidate();
  }

  myUp(){
    this.isDrag = false;
    this.isResizeDrag = false;
    this.expectResize = -1;
  }

  myDblClick(e) {
    this.getMouse(e);
    var width = 20;
    var height = 20;
    this.addRect(this.mx - (width / 2), this.my - (height / 2), width, height, 'rgba(220,205,65,0.7)');
  }


  invalidate() {
    this.canvasValid = false;
  }

  getMouse(e) {
    if (this.canvas) {
          var element = this.canvas, offsetX = 0, offsetY = 0;

          if (e.touches) {
            e = e.touches[0]
          }

          if (element.offsetParent) {
            do {
              offsetX += element.offsetLeft;
              offsetY += element.offsetTop;
            } while ((element = element.offsetParent));
          }

          offsetX += this.stylePaddingLeft;
          offsetY += this.stylePaddingTop;

          offsetX += this.styleBorderLeft;
          offsetY += this.styleBorderTop;
          this.mx = e.pageX - offsetX;
          this.my = e.pageY - offsetY

          var rect = this.canvas.getBoundingClientRect();

          var scaleX = this.canvas.width / rect.width;
          var scaleY = this.canvas.height / rect.height;

          this.mx = (e.clientX - rect.left) * scaleX;
          this.my = (e.clientY - rect.top) * scaleY;
      }
  }

  render() {
    return (
      <canvas ref={this.ref} className="atop" onMouseDown={this.myDown.bind(this)} onTouchStart={this.myDown.bind(this)} onMouseUp={this.myUp.bind(this)} onTouchEnd={this.myUp.bind(this)} onMouseMove={this.myMove.bind(this)} onTouchMove={this.myMove.bind(this)}></canvas>
    );
  }
}

export default Select;
