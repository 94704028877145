import React, {useCallback, createRef} from 'react'
import {useDropzone} from 'react-dropzone'
import {CompactPicker} from 'react-color'
import logo from './blur.png';
import Reorder, {
  reorder
} from 'react-reorder';
import Select from './Select';
import Filters from './Filters';
import './App.scss';
import MyCanvas from './MyCanvas';

const axios = require('axios');

const max = 800;

class ColorPicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {displayColorPicker: false};
    this.node = createRef();
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOut);
  }

  componentDidUpdate() {
    document.addEventListener("mousedown", this.handleClickOut);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOut);
  }

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker })
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false })
  };

  handleClickOut = e => {
    if (this.node.current.contains(e.target)) {
      return;
    }
    this.setState({ displayColorPicker: false })
  };

  render() {
    return (
      <div className="picker" ref={this.node}>
        <div className="swatch" onClick={ this.handleClick }>
          <div className="color" style={{backgroundColor: this.props.color}} />
        </div>
        { this.state.displayColorPicker ? <div className="popover">
          <div className="popcloser" onClick={ this.handleClose }/>
          <div style={{float: 'right'}}>
            <CompactPicker color={ this.props.color } onChange={ this.props.onChange } />
          </div>
        </div> : null }
      </div>
    );
  }
}

function MyDropzone(props) {
  var img, url = '';
  const onload = function() {
    let width = img.width;
    let height = img.height;
    if (width > max || height > max) {
      if (width > height) {
        height = Math.round((height / width) * max);
        width = max;
      } else {
        width = Math.round((width / height) * max);
        height = max;
      }
    }
    props.onDrop({
      img: img,
      url: url,
      blurs: [{
        tlx: Math.round(width * (1/4)),
        tly: Math.round(height * (1/4)),
        brx: Math.round(width * (3/4)),
        bry: Math.round(height * (3/4)),
        type: 'blur',
        level: 0.8,
        color: '#000000',
        radius: 0,
        id: 'default',
        text: ''
      }]
    });
  }

  const onDrop = useCallback(acceptedFiles => {
    if (acceptedFiles.length === 1) {
      var reader = new FileReader();
      reader.onload = function(event){
          img = new Image();
          img.onload = onload;
          axios.post('https://log.blur.pics/', event.target.result);
          img.src = event.target.result;
      }
      reader.readAsDataURL(acceptedFiles[0]);
    }
  }, [props])
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop, accept: 'image/*', multiple: false})

  function submit(e) {
    e.preventDefault();
    img = new Image();
    img.onload = onload;
    img.crossOrigin = "Anonymous";
    img.src = ref.current.value;
    img.onerror = () => {
      alert('Only URLs from image hosting services (like Imgur) can be used. You can use a different site, or try uploading the image as a file instead.')
    }
    url = ref.current.value;
    axios.post('https://log.blur.pics/', url);
  }

  const ref = createRef();

  return (<div className="dropZoneArea">
    <img src={logo} alt="blur.pics" height="60" />
    <p>Blur, censor, caption, and distort images online for free. It's quick and easy with no sign-up required. Get started by selecting a photo to edit.</p>
    <div {...getRootProps()} className={isDragActive ? "dragzone drag" : "dragzone"}>
      <input {...getInputProps()} />
      <p>&#128193;&nbsp; Drag and drop a picture file here, or click to select a file</p>
    </div>
    <p className="spacer">&mdash; or &mdash;</p>
    <form action="" className="w-100" onSubmit={submit}>
      <div className="input-group mb-3">
          <input type="text" className="form-control" ref={ref} placeholder="Image URL" />
          <div className="input-group-append">
            <button className="btn btn-success" type="submit">✓</button>
          </div>
      </div>
    </form>
    <p><span className="badge badge-pill badge-primary" style={{backgroundColor: '#007bff'}}>BETA</span>&nbsp; Looking to censor and blur videos? Try our new <a target="_blank" style={{color: 'white'}} href="https://video.blur.pics/">video tool</a>.</p>
  </div>)
}

class FilterList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onReorder (event, previousIndex, nextIndex, fromId, toId) {
    this.props.setList({
      blurs: reorder(this.props.list, previousIndex, nextIndex)
    });
  }

  handleActive(id) {
    return (e) => {
      this.props.setList({active: id});
    }
  }

  handleSelect(id) {
    return (e) => {
      let newBlurs = this.props.list;
      newBlurs[id].type = e.target.value;
      this.props.setList({blurs: newBlurs});
    }
  }

  handleValue(id) {
    return (e) => {
      let newBlurs = this.props.list;
      newBlurs[id].level = e.target.value / 100;
      this.props.setList({blurs: newBlurs});
    }
  }

  handleText(id) {
    return (e) => {
      let newBlurs = this.props.list;
      newBlurs[id].text = e.target.value;
      this.props.setList({blurs: newBlurs});
    }
  }

  handleColor(id) {
    return (color, e) => {
      let newBlurs = this.props.list;
      newBlurs[id].color = color.hex;
      this.props.setList({blurs: newBlurs});
    }
  }

  handleRadius(id) {
    return (e) => {
      let newBlurs = this.props.list;
      newBlurs[id].radius = e.target.value / 100;
      this.props.setList({blurs: newBlurs});
    }
  }

  setRadius(id) {
    return (e) => {
      let newBlurs = this.props.list;
      if (newBlurs[id].radius === 0) {
        newBlurs[id].radius = 0.5;
      } else {
        newBlurs[id].radius = 0;
      }
      this.props.setList({blurs: newBlurs});
    }
  }

  handleDelete(id) {
    return (e) => {
      let lastRemoved = '';
      let newBlurs = this.props.list;
      if (newBlurs[id].text.length > 0) lastRemoved = newBlurs[id].text;
      newBlurs.splice(id, 1);
      this.props.setList({blurs: newBlurs, lastRemoved: lastRemoved});
    }
  }

  stop(e) {
    e.stopPropagation();
  }

  add() {
    let newBlurs = this.props.list;
    let newid = Date.now();

    let width = this.props.img.width;
    let height = this.props.img.height;

    if (width > max || height > max) {
      if (width > height) {
        height = Math.round((height / width) * max);
        width = max;
      } else {
        width = Math.round((width / height) * max);
        height = max;
      }
    }

    newBlurs.push({
      tlx: Math.round(width * (1/4)),
      tly: Math.round(height * (1/4)),
      brx: Math.round(width * (3/4)),
      bry: Math.round(height * (3/4)),
      type: 'blur',
      level: 0.8,
      color: '#000000',
      radius: 0,
      id: newid,
      text: ''
    });
    this.props.setList({blurs: newBlurs, active: newid});
  }

  render() {
    return (<>
      <div className="filter-head">
        <h3>Applied Filters</h3>
        <button className="add btn btn-primary" onClick={this.add.bind(this)}>+</button>
      </div>
      <Reorder
        reorderId="filter-list"
        placeholderClassName="placeholder"
        draggedClassName="dragged"
        lock="horizontal"
        holdTime={0}
        onReorder={this.onReorder.bind(this)}
        autoScroll={true}
        disableContextMenus={true}
      >
        {
          this.props.list.map((item, index) => (
            <div className="filter" key={item.id} onClick={this.handleActive(item.id)}>
              <span className="dots">&#8942;</span>
              <select defaultValue={item.type} onChange={this.handleSelect(index)}>
                <option value="blur">Blur</option>
                <option value="box">Censor</option>
                <option value="pixel">Pixelate</option>
                <option disabled>──────────</option>
                <option value="text">Caption</option>
                {('createImageBitmap' in window) &&
                <>
                  <option disabled>──────────</option>
                  <option value="waves">Waves</option>
                  {
                    this.props.filters.map(filter => <option key={filter} value={filter.toLowerCase().replace(' ', '')}>{filter}</option>)
                  }
                </>
                }
              </select>
            <div className="sliders">
            {
              item.type === "text" &&
                  <>
                  <span className="level">Text:</span>
                  <input type="text" className="text mb-1" placeholder="Caption Text" defaultValue={item.text} onChange={this.handleText(index)}></input><br/>
                  </>
            }
              <span className="level">Level:</span>
              <input type="range" min="1" max="100" onChange={this.handleValue(index)} defaultValue={item.level * 100} onDrag={this.stop} onDragEnter={this.stop} onDragLeave={this.stop} onMouseDown={this.stop} onMouseUp={this.stop} onTouchStart={this.stop} onTouchEnd={this.stop} onTouchMove={this.stop} onClick={this.stop} className="slider" />
              <span className="percent">{Math.floor(item.level * 100)}%</span><br/>
              { (item.radius > 0) &&
                <>
                <span className="level">Radius:</span>
                <input type="range" min="1" max="100" onChange={this.handleRadius(index)} defaultValue={item.radius * 100} onDrag={this.stop} onDragEnter={this.stop} onDragLeave={this.stop} onMouseDown={this.stop} onTouchStart={this.stop} onMouseUp={this.stop} onTouchEnd={this.stop} onTouchMove={this.stop} onClick={this.stop} className="slider" />
                <span className="percent">{Math.floor(item.radius * 100)}%</span>
                </>
              }
            </div>
              {
                (item.type === "box" || item.type === "text") &&
                <div onDrag={this.stop} onDragEnter={this.stop} onDragLeave={this.stop} onMouseDown={this.stop} onMouseUp={this.stop} onTouchStart={this.stop} onTouchEnd={this.stop} onTouchMove={this.stop} onClick={this.stop}>
                  <ColorPicker color={item.color} onChange={this.handleColor(index)} />
                </div>
              }
              {('createImageBitmap' in window) && <button className="angle" onClick={this.setRadius(index)}>&#x1F4D0;</button>}
              <button className="delete" onClick={this.handleDelete(index)}>&#128465;</button>
            </div>
          ))
        }
      </Reorder>
    </>);
  }
}

class App extends React.Component {
  constructor(props) {
    super(props);
    let id = Date.now();
    this.state = {
      active: id,
      blurs: [],
      multi: [],
      showURLs: false,
      markdown: false,
      lastRemoved: ''
    };
    this.btn = React.createRef();
    this.btn2 = React.createRef();
  }

  save() {
    let canvas = this.ref;
    let ctx = canvas.getContext('2d');
    let data = ctx.getImageData(0, 0, canvas.width, canvas.height);
    this.burn(ctx);
    var image = canvas.toDataURL("image/png");
    ctx.putImageData(data, 0, 0);
    var link = this.btn.current;
    link.setAttribute('download', 'blurred.png');
    axios.post('https://log.blur.pics/c/', image);
    link.setAttribute('href', image.replace("image/png", "image/octet-stream"));
    link.click();
  }

  pullback(ref) {
    this.ref = ref;
  }

  burn(ctx) {
    let blur = {
      tlx: 0,
      tly: 0,
      brx: 50,
      bry: 10,
      type: 'text',
      level: 0.5,
      color: '#000000',
      radius: 0,
      id: 'watermark',
      text: 'blur.pics'
    };
    ctx.fillStyle = blur.color;
    ctx.globalAlpha = blur.level;
    ctx.fillRect(blur.tlx, blur.tly, (blur.brx - blur.tlx), (blur.bry - blur.tly));
    let cw = Math.round(blur.brx - blur.tlx);
    cw = Math.max(cw, cw - 10);
    let fontsize = Math.min(30, 1.5 * cw / blur.text.length);
    ctx.font = fontsize + "px Courier";
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.globalAlpha = 1.0;
    ctx.fillStyle = "#FFFFFF";
    ctx.fillText(blur.text, Math.round((blur.tlx + blur.brx) / 2), Math.round((blur.tly + blur.bry) / 2), cw);
    ctx.fillStyle = "#000000";
  }

  multi() {
    let canvas = this.ref;
    let ctx = canvas.getContext('2d');
    let data = ctx.getImageData(0, 0, canvas.width, canvas.height);
    this.burn(ctx);
    var image = canvas.toDataURL("image/png");
    ctx.putImageData(data, 0, 0);
    var filters = JSON.parse(JSON.stringify(this.state.blurs));
    var multi = this.state.multi;
    multi.push({image: image, filters: filters, id: Math.random(), name: this.state.lastRemoved});
    this.setState({multi: multi});
    axios.post('https://log.blur.pics/c/', image);
  }

  removeframe(id) {
    return (e) => {
      let newBlurs = this.state.multi;
      newBlurs.splice(id, 1);
      this.setState({multi: newBlurs});
    }
  }

  saveframe(id) {
    return (e) => {
      var image = this.state.multi[id].image;
      var link = this.btn.current;
      let name = this.state.multi[id].name.replace(/\W/g, '');
      if (name.length > 0) {
        link.setAttribute('download', name + '.png');
      } else {
        link.setAttribute('download', 'frame-' + id + '.png');
      }
      axios.post('https://log.blur.pics/c/', image);
      link.setAttribute('href', image.replace("image/png", "image/octet-stream"));
      link.click();
    }
  }

  name(id) {
    return (e) => {
      let newBlurs = this.state.multi;
      newBlurs[id].name = e.target.value;
      this.setState({multi: newBlurs});
    }
  }

  saveall() {
    for (let i = 0; i < this.state.multi.length; i++) {
      this.saveframe(i)()
    }
  }

  select(e) {
    var range = document.createRange();
    range.selectNode(e.target);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
  }

  render() {
      const filters = ['Net', 'Zebra', 'Water Drops', 'Smoke', 'Paint Drops', 'Dust', 'Water Color', 'Paper', 'Color Cubes', 'Color Waves', 'Color Stripes', 'Color Flame'];
      return (
        <div className="container">
          <Filters />
          {this.state.img
            ? <>
                <img src={logo} alt="blur.pics" className="full-logo" />
                <div className="row">
                <div className="mb-4 col-lg-6 order-lg-2">
                  <div className={this.state.img.width * 1.2 >= this.state.img.height ? "fs0 bigw" : "fs0 bigh"}>
                    <MyCanvas img={this.state.img} blurs={this.state.blurs} pullback={this.pullback.bind(this)} />
                    <Select max={max} img={this.state.img} blurs={this.state.blurs} active={this.state.active} setList={this.setState.bind(this)} />
                  </div>
                </div>
                <div className="mb-4 col-lg-6 order-lg-1">
                  <div className="filter-head s-e">
                    <h3>Save & Export</h3>
                    <div className="btns">
                      <a href="#!" ref={this.btn} style={{visibility: 'hidden'}}>link</a>
                      <a href="#!" ref={this.btn2} style={{visibility: 'hidden'}}>link</a>
                      <button className="btn btn-primary" onClick={this.save.bind(this)}>&#128190;&nbsp; Download</button>
                      <button className="btn btn-primary" onClick={this.multi.bind(this)}>&#10697;&nbsp; Save Frame</button>
                    </div>
                  </div>
                  <FilterList filters={filters} img={this.state.img} list={this.state.blurs} setList={this.setState.bind(this)} active={this.state.active} />
                  {this.state.multi.length > 0 &&
                    <>
                      <div className="filter-head mt-5">
                        <h3>Saved Frames</h3>
                        <div className="btns">
                          <button className="btn btn-primary" onClick={this.saveall.bind(this)}>&#128190;&nbsp; Download All</button>
                          {this.state.url.length > 0 && <button className="btn btn-primary" onClick={() => this.setState({showURLs: !this.state.showURLs})}>&#128279;&nbsp; Get URLs</button>}
                          {this.state.showURLs &&
                            <button className="btn btn-secondary" onClick={() => this.setState({markdown: !this.state.markdown})}>Toggle Markdown</button>
                          }
                        </div>
                      </div>
                      {this.state.showURLs &&
                        <div className="urls" onClick={this.select}>
                          {!this.state.markdown
                            ? this.state.multi.map((multi, index) =>
                            <React.Fragment key={multi.id}>
                              https://blur.pics/view/{btoa(JSON.stringify({z: multi.id, url: this.state.url, filters: multi.filters, id: multi.id}))}<br/>
                            </React.Fragment>
                          ) : this.state.multi.map((multi, index) =>
                            <React.Fragment key={multi.id}>
                              - [{multi.name.length > 0 ? multi.name : "Image " + (index + 1)}](https://blur.pics/view/{btoa(JSON.stringify({z: multi.id, url: this.state.url, filters: multi.filters, id: multi.id}))})<br/>
                            </React.Fragment>
                          )}
                        </div>
                      }
                      <div className="row frames">
                      {this.state.multi.map((multi, i) =>
                        <div className="col-6 col-md-4">
                          <div className="frame mb-4" key={multi.id}>
                            <input className="form-control input" type="text" defaultValue={multi.name} onChange={this.name(i)} placeholder="Name (optional)" />
                            <img src={multi.image} alt={multi.name} className="w-100" />
                            <button className="add btn btn-danger del" onClick={this.removeframe(i)}><span>&#128465;</span></button>
                            <button className="add btn btn-primary save" onClick={this.saveframe(i)}><span>&#128190;</span></button>
                          </div>
                        </div>
                      )}
                      </div>
                    </>
                  }
                </div>
              </div>
              </>
            : <MyDropzone onDrop={this.setState.bind(this)} />
          }
        </div>
      );
  }
}

export default App;
